/**
 * @generated SignedSource<<115cf7b5fa901747baf10eb08c644b43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FloorPriceDifference_perUnitPrice$data = {
  readonly eth: string;
  readonly " $fragmentType": "FloorPriceDifference_perUnitPrice";
};
export type FloorPriceDifference_perUnitPrice$key = {
  readonly " $data"?: FloorPriceDifference_perUnitPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"FloorPriceDifference_perUnitPrice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FloorPriceDifference_perUnitPrice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eth",
      "storageKey": null
    }
  ],
  "type": "PriceType",
  "abstractKey": null
};

(node as any).hash = "ff6cb0d22f21f81765cf96a223b2d694";

export default node;
