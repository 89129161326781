import { format, isToday, min } from "date-fns"
import { DATE_FORMAT_STRING } from "@/constants/datetime"
import { useI18nDateAtTime, useFromNow } from "@/lib/helpers/datetime"
import { EM_DASH } from "@/lib/helpers/stringUtils"

const formatAsDate = (date: Date | null | undefined): string => {
  if (!date) {
    return EM_DASH
  }
  return format(date, DATE_FORMAT_STRING)
}

type GetFormattedExpirationDateReturn = {
  formattedExpirationDate: string
  formattedExpirationDateExact: string | undefined
}

type GetFormattedExpirationDateOption = {
  maxExpirationDate?: Date
  alwaysRelative?: boolean
}

export const useFormattedExpirationDate = (
  expirationDate: Date | null,
  {
    maxExpirationDate,
    alwaysRelative = false,
  }: GetFormattedExpirationDateOption = {},
): GetFormattedExpirationDateReturn => {
  const renderFromNow = useFromNow()

  let limitedExpirationDate = expirationDate || maxExpirationDate || new Date()
  if (expirationDate && maxExpirationDate) {
    limitedExpirationDate = min([expirationDate, maxExpirationDate])
  }

  const limitedExpirationDisplay = useI18nDateAtTime(limitedExpirationDate)

  // Use maxExpirationDate or undefined and guard aginst returning the placeholder "new Date()"
  if (expirationDate === null) {
    return {
      formattedExpirationDate: formatAsDate(maxExpirationDate),
      formattedExpirationDateExact: maxExpirationDate
        ? limitedExpirationDisplay
        : undefined,
    }
  }

  const shouldDisplayRelativeDate =
    alwaysRelative || isToday(limitedExpirationDate)

  if (shouldDisplayRelativeDate) {
    return {
      formattedExpirationDate: renderFromNow(limitedExpirationDate),
      formattedExpirationDateExact: limitedExpirationDisplay,
    }
  }

  return {
    formattedExpirationDate: formatAsDate(limitedExpirationDate),
    formattedExpirationDateExact: limitedExpirationDisplay,
  }
}
