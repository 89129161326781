import React, { ReactNode } from "react"
import { Text, InputProps } from "@opensea/ui-kit"
import { RegisterOptions } from "react-hook-form"
import { Link } from "@/components/common/Link"
import { CREATOR_FEES_ARTICLE } from "@/constants/support-articles"
import { useMarketplaceFeePromotionEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import {
  basisPointsToPercentage,
  BigNumber,
  bn,
  isValidNumericInput,
  NumberInput,
} from "@/lib/helpers/numberUtils"

const MAX_CREATOR_FEE_DECIMALS = 2

type Input = {
  maxBasisPoints: NumberInput
  enforcedBasisPoints?: NumberInput
  isCreatorFeesEnforced?: boolean
}

type Result = {
  label: ReactNode
  caption: ReactNode
  tooltipContent: ReactNode
  inputProps: InputProps
  registerOptions: RegisterOptions
}

export const useOptionalCreatorFeeInput = ({
  maxBasisPoints,
  enforcedBasisPoints,
  isCreatorFeesEnforced,
}: Input): Result => {
  const t = useTranslate("hooks")
  const isMarketplaceFeePromotionEnabled = useMarketplaceFeePromotionEnabled()
  const minBasisPoints =
    enforcedBasisPoints ??
    (isMarketplaceFeePromotionEnabled ? BigNumber.min(50, maxBasisPoints) : 0)
  const minPercent = basisPointsToPercentage(minBasisPoints)
  const maxPercent = bn(maxBasisPoints).gte(minBasisPoints)
    ? basisPointsToPercentage(maxBasisPoints)
    : basisPointsToPercentage(minBasisPoints)

  const label = t("creatorFeeInput.label", "Creator earnings")
  const captionPromo = t(
    "creatorFeeInput.captionPromo",
    "Creator earnings are optional for this collection. You can give them between {{minPercent}}% to {{maxPercent}}% of your sale.",
    {
      minPercent,
      maxPercent,
    },
  )

  const noCreatorFeeMessage = t(
    "creatorFeeInput.noCreatorFeeCaption",
    "This creator hasn't enabled creator earnings.",
  )

  const caption = bn(maxPercent).eq(0)
    ? noCreatorFeeMessage
    : isCreatorFeesEnforced
    ? t(
        "creatorFeeInput.enforcedCreatorFeeCaption",
        "{{percent}}% creator earnings are enforced on this collection.",
        {
          percent: maxPercent,
        },
      )
    : t(
        "creatorFeeInput.suggestedCreatorFeeCaption",
        "This creator suggested creator earnings of {{percent}}%.",
        {
          percent: maxPercent,
        },
      )
  const tooltipContent = bn(maxPercent).gt(0)
    ? t(
        "creatorFeeInput.tooltipText",
        "100% of creator earnings are paid to the creator of the collection. {{learnMoreLink}}",
        {
          learnMoreLink: (
            <Link href={CREATOR_FEES_ARTICLE}>
              {t("headerLabel.creatorEarnings.learnMoreLink", "Learn more")}
            </Link>
          ),
        },
      )
    : noCreatorFeeMessage
  const inputProps = {
    endEnhancer: (
      <Text.Body className="text-secondary" size="medium">
        %
      </Text.Body>
    ),
  }
  const registerOptions = {
    min: {
      value: minPercent,
      message: t(
        "form.creatorEarnings.validation.minimum",
        "Enter an amount greater than or equal to {{min}}%.",
        { min: minPercent },
        { forceString: true },
      ),
    },
    max: {
      value: maxPercent,
      message: t(
        "form.creatorEarnings.validation.maximum",
        "Enter an amount up to {{max}}%.",
        { max: maxPercent },
        { forceString: true },
      ),
    },
    validate: {
      isValidNumber: (value: string) =>
        !bn(value).isNaN() ||
        t("numberInput.invalidAmountPrompt", "Enter a valid number."),
      maxDecimals: (value: string) =>
        isValidNumericInput(value, MAX_CREATOR_FEE_DECIMALS) ||
        t(
          "numberInput.invalidDecimalsPrompt",
          "Limit of {{maxDecimals}} decimals.",
          { maxDecimals: MAX_CREATOR_FEE_DECIMALS },
          { forceString: true },
        ),
    },
  }

  return {
    label,
    caption: isMarketplaceFeePromotionEnabled ? captionPromo : caption,
    tooltipContent,
    inputProps,
    registerOptions,
  }
}
