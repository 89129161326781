/**
 * @generated SignedSource<<fb5edbec7247639773352fd16ecd104a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readOptionalCreatorFees_item$data = {
  readonly __typename: "AssetType";
  readonly collection: {
    readonly isCreatorFeesEnforced: boolean;
    readonly totalCreatorFeeBasisPoints: number;
  };
  readonly " $fragmentType": "readOptionalCreatorFees_item";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "readOptionalCreatorFees_item";
};
export type readOptionalCreatorFees_item$key = {
  readonly " $data"?: readOptionalCreatorFees_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOptionalCreatorFees_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readOptionalCreatorFees_item"
};

(node as any).hash = "bdbfab6290329cfac992d43ab11e3e96";

export default node;
