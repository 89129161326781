import React from "react"
import { Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { ItemQuantityBadge } from "@/components/assets/ItemQuantityBadge.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Item, ItemProps } from "@/design-system/Item"
import { ItemOfferDetails_item$key } from "@/lib/graphql/__generated__/ItemOfferDetails_item.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"

type ItemOfferDetailsProps = {
  item: ItemOfferDetails_item$key | null
  quantity: number
  renderPriceSide: () => React.ReactNode
  renderRarityRank?: () => React.ReactNode
} & ItemProps

export const ItemOfferDetails = ({
  item: itemDataKey,
  quantity,
  renderPriceSide,
  renderRarityRank,
  ...itemProps
}: ItemOfferDetailsProps) => {
  const item = useFragment(
    graphql`
      fragment ItemOfferDetails_item on ItemType {
        __typename

        ... on AssetType {
          displayName
          collection {
            ...CollectionLink_collection
          }
          ...StackedAssetMedia_assets
        }

        ... on AssetBundleType {
          displayName
          bundleCollection: collection {
            ...CollectionLink_collection
          }
          assetQuantities(first: 18) {
            edges {
              node {
                asset {
                  ...StackedAssetMedia_assets
                }
              }
            }
          }
        }
      }
    `,
    itemDataKey,
  )

  if (!item || item.__typename === "%other") {
    return null
  }

  const collection =
    item.__typename === "AssetType" ? item.collection : item.bundleCollection

  return (
    <Item
      {...itemProps}
      className={classNames("border-0", itemProps.className)}
    >
      <ItemQuantityBadge
        overrides={{
          QuantityBadge: { props: { className: classNames("right-3") } },
        }}
        quantity={quantity}
      >
        <Item.Avatar size={84}>
          <StackedAssetMedia
            assets={
              item.__typename === "AssetType"
                ? [item]
                : getNodes(item.assetQuantities).map(
                    assetQuantity => assetQuantity.asset,
                  )
            }
            variant="small"
          />
        </Item.Avatar>
      </ItemQuantityBadge>
      <Item.Content>
        <Item.Title>
          <Text
            asChild
            data-testid="ItemOfferDetails--name"
            size="medium"
            weight="semibold"
          >
            <div>{item.displayName}</div>
          </Text>
        </Item.Title>

        {collection && (
          <Item.Description>
            <Text asChild size="small">
              <div>
                <CollectionLink
                  collection={collection}
                  isSmall
                  linkStyle={{
                    color: "inherit",
                    fontSize: "inherit",
                    fontWeight: "inherit",
                  }}
                />
              </div>
            </Text>
          </Item.Description>
        )}

        {renderRarityRank && (
          <Item.Description>{renderRarityRank()}</Item.Description>
        )}
      </Item.Content>
      <Item.Side>{renderPriceSide()}</Item.Side>
    </Item>
  )
}
