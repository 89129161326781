import React from "react"
import { identity } from "lodash"
import { useFragment } from "react-relay"
import { OrderUsdPrice$key } from "@/lib/graphql/__generated__/OrderUsdPrice.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { FiatPrice, FiatPriceProps } from "./FiatPrice.react"

type Variant = "perUnit" | "total"

type OrderUsdPriceProps = Pick<
  FiatPriceProps,
  "className" | "secondary" | "color" | "fontWeight"
> & {
  order: OrderUsdPrice$key
  variant?: Variant
  map?: (price: BigNumber) => BigNumber
}

export const OrderUsdPrice = ({
  order,
  variant = "total",
  map = identity,
  ...rest
}: OrderUsdPriceProps) => {
  const { priceType, perUnitPriceType } = useFragment(
    graphql`
      fragment OrderUsdPrice on OrderV2Type {
        priceType {
          usd
        }
        perUnitPriceType {
          usd
        }
      }
    `,
    order,
  )

  const basePrice =
    variant === "total" ? bn(priceType.usd) : bn(perUnitPriceType.usd)

  const currentPrice = bn(basePrice)

  const price = map(currentPrice)

  return <FiatPrice price={price} {...rest} />
}
