import React, { CSSProperties } from "react"
import BigNumber from "bignumber.js"
import { Overflow } from "@/components/common/Overflow"
import { displayFiat } from "@/lib/helpers/numberUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { PriceContainer } from "./styles"

export type FiatPriceProps = {
  price: BigNumber
  secondary?: boolean
  className?: string
  color?: CSSProperties["color"]
  fontWeight?: CSSProperties["fontWeight"]
  position?: CSSProperties["position"]
}

export const FiatPrice = ({
  price,
  className,
  color,
  fontWeight,
  position,
  secondary,
}: FiatPriceProps) => {
  const priceDisplay = displayFiat(price)
  return (
    <PriceContainer
      className={className}
      color={color}
      fontWeight={fontWeight}
      position={position}
    >
      <Overflow
        className={selectClassNames("Price", {
          "fiat-amount": true,
          "fiat-amount-secondary": secondary,
        })}
      >
        {secondary ? `${priceDisplay}` : priceDisplay}
      </Overflow>
    </PriceContainer>
  )
}
