/**
 * @generated SignedSource<<4cc4a0d5a878689960b4a7bd2e654c3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcceptHighestOfferButton_tradeSummary$data = {
  readonly bestBid: {
    readonly item: {
      readonly " $fragmentSpreads": FragmentRefs<"itemEvents_dataV2">;
    };
    readonly perUnitPriceType: {
      readonly symbol: string;
      readonly unit: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_order">;
  } | null;
  readonly " $fragmentType": "AcceptHighestOfferButton_tradeSummary";
};
export type AcceptHighestOfferButton_tradeSummary$key = {
  readonly " $data"?: AcceptHighestOfferButton_tradeSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptHighestOfferButton_tradeSummary">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptHighestOfferButton_tradeSummary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestBid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "itemEvents_dataV2",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "relayId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChainType",
                  "kind": "LinkedField",
                  "name": "chain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "identifier",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tokenId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetContractType",
                      "kind": "LinkedField",
                      "name": "assetContract",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "address",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "AssetType",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "perUnitPriceType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AcceptOfferButton_order"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeSummaryType",
  "abstractKey": null
};

(node as any).hash = "7a56832927e0840eb5c01216a1675ab3";

export default node;
