import React from "react"
import { TextBodyProps, Text } from "@opensea/ui-kit"
import { meanBy } from "lodash"
import { graphql, useFragment } from "react-relay"
import { Block } from "@/design-system/Block"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { ServiceFeeText_orders$key } from "@/lib/graphql/__generated__/ServiceFeeText_orders.graphql"
import { basisPointsToPercentage } from "@/lib/helpers/numberUtils"

type Props = {
  orders: ServiceFeeText_orders$key | null
} & Partial<TextBodyProps>

const OLD_SERVICE_FEE_BASIS_POINTS = 250

export const ServiceFeeText = ({
  orders: ordersDataKey,
  ...textProps
}: Props) => {
  const orders = useFragment(
    graphql`
      fragment ServiceFeeText_orders on OrderV2Type @relay(plural: true) {
        ...readOrderFees_order
      }
    `,
    ordersDataKey,
  )

  const averageOpenseaSellerFeeBasisPoints = meanBy(
    orders,
    order => readOrderFees(order).openseaSellerFeeBasisPoints,
  )

  const showLowerServiceFee =
    averageOpenseaSellerFeeBasisPoints < OLD_SERVICE_FEE_BASIS_POINTS

  return (
    <Block>
      {showLowerServiceFee && (
        <Text className="mr-2 line-through" size="medium" {...textProps}>
          {basisPointsToPercentage(OLD_SERVICE_FEE_BASIS_POINTS)}%
        </Text>
      )}
      <Text
        color={showLowerServiceFee ? "success" : undefined}
        size="medium"
        {...textProps}
      >
        {basisPointsToPercentage(averageOpenseaSellerFeeBasisPoints)}%
      </Text>
    </Block>
  )
}
