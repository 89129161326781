/**
 * @generated SignedSource<<28ec99738c5c9bf0410632859f88e6b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcceptHighestOfferButton_asset$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_asset" | "itemEvents_dataV2">;
  readonly " $fragmentType": "AcceptHighestOfferButton_asset";
};
export type AcceptHighestOfferButton_asset$key = {
  readonly " $data"?: AcceptHighestOfferButton_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptHighestOfferButton_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptHighestOfferButton_asset",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptOfferButton_asset"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_dataV2",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "type": "ItemType",
          "abstractKey": "__isItemType"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "327b4f98aa89c83573c2b45adc6068a6";

export default node;
