/**
 * @generated SignedSource<<774797f6ed5ce0f5b81b623450e75aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FloorPriceDifference_item$data = {
  readonly collection?: {
    readonly statsV2: {
      readonly floorPrice: {
        readonly eth: string;
      } | null;
    };
  };
  readonly " $fragmentType": "FloorPriceDifference_item";
};
export type FloorPriceDifference_item$key = {
  readonly " $data"?: FloorPriceDifference_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"FloorPriceDifference_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FloorPriceDifference_item",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionStatsV2Type",
              "kind": "LinkedField",
              "name": "statsV2",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "floorPrice",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "eth",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "ec98b3e99519ae71470c6211102f1eb0";

export default node;
