/**
 * @generated SignedSource<<d43a9b5b532f7e82a1983299e02a3002>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderUsdPrice$data = {
  readonly perUnitPriceType: {
    readonly usd: string;
  };
  readonly priceType: {
    readonly usd: string;
  };
  readonly " $fragmentType": "OrderUsdPrice";
};
export type OrderUsdPrice$key = {
  readonly " $data"?: OrderUsdPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderUsdPrice">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "usd",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderUsdPrice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "priceType",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "16689061aade6e8223a2a50a9d3dbf27";

export default node;
