import React from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { AcceptHighestOfferButton_asset$key } from "@/lib/graphql/__generated__/AcceptHighestOfferButton_asset.graphql"
import { AcceptHighestOfferButton_tradeSummary$key } from "@/lib/graphql/__generated__/AcceptHighestOfferButton_tradeSummary.graphql"
import {
  AcceptHighestOfferButtonQuery,
  AcceptHighestOfferButtonQuery$variables,
} from "@/lib/graphql/__generated__/AcceptHighestOfferButtonQuery.graphql"
import {
  roundAboveMin,
  MAX_DISPLAYED_DECIMAL_PLACES,
} from "@/lib/helpers/numberUtils"
import { AcceptOfferButton, AcceptOfferButtonProps } from "../AcceptOfferButton"
import { Source, trackClickAcceptHighestOffer } from "../analytics"

type Props = {
  tradeSummary: AcceptHighestOfferButton_tradeSummary$key
  criteriaAsset?: AcceptHighestOfferButton_asset$key
  source: Source
} & Omit<AcceptOfferButtonProps, "order" | "criteriaAsset">

export const AcceptHighestOfferButton = ({
  tradeSummary: tradeSummaryDataKey,
  criteriaAsset: criteriaAssetDataKey,
  source,
  ...acceptOfferButtonProps
}: Props) => {
  const t = useTranslate("orders")
  const { bestBid } = useFragment(
    graphql`
      fragment AcceptHighestOfferButton_tradeSummary on TradeSummaryType {
        bestBid {
          item {
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...itemEvents_dataV2
          }
          perUnitPriceType {
            unit
            symbol
          }
          ...AcceptOfferButton_order
        }
      }
    `,
    tradeSummaryDataKey,
  )

  const criteriaAsset = useFragment(
    graphql`
      fragment AcceptHighestOfferButton_asset on AssetType {
        ...AcceptOfferButton_asset
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...itemEvents_dataV2
      }
    `,
    criteriaAssetDataKey ?? null,
  )

  if (!bestBid) {
    return null
  }

  return (
    <AcceptOfferButton
      criteriaAsset={criteriaAsset ?? undefined}
      order={bestBid}
      source={source}
      variant="secondary"
      onClick={() => {
        trackClickAcceptHighestOffer(criteriaAsset ?? bestBid.item, { source })
      }}
      {...acceptOfferButtonProps}
    >
      <ButtonWithPriceLabelContainer>
        {t("acceptOffer", "Accept offer")}
      </ButtonWithPriceLabelContainer>
      {roundAboveMin(
        bestBid.perUnitPriceType.unit,
        MAX_DISPLAYED_DECIMAL_PLACES,
      )}{" "}
      {bestBid.perUnitPriceType.symbol}
    </AcceptOfferButton>
  )
}

// TODO(CORE-2913): Create a dedicated Button component for this
export const ButtonWithPriceLabelContainer = styled(Block)`
  padding-right: 12px;
  border-right: 2px solid
    ${props =>
      props.theme.type === "light"
        ? props.theme.colors.fog
        : props.theme.colors.ash};

  margin-right: 12px;
`

type LazyAcceptHighestOfferButtonProps = {
  suspenseFallback?: React.ReactNode
} & Omit<AcceptHighestOfferButtonQuery$variables, "archetype"> &
  Omit<Props, "tradeSummary" | "criteriaAsset">

const LazyAcceptHighestOfferButtonBase = ({
  contractAddress,
  tokenId,
  chain,
  ...rest
}: LazyAcceptHighestOfferButtonProps) => {
  const { nft } = useLazyLoadQuery<AcceptHighestOfferButtonQuery>(
    graphql`
      query AcceptHighestOfferButtonQuery(
        $tokenId: String!
        $contractAddress: AddressScalar!
        $chain: ChainScalar!
      ) {
        nft(
          contractAddress: $contractAddress
          tokenId: $tokenId
          chain: $chain
        ) {
          ...AcceptHighestOfferButton_asset
          tradeSummary(excludeAccountAsMaker: true) {
            ...AcceptHighestOfferButton_tradeSummary
          }
        }
      }
    `,
    {
      contractAddress,
      tokenId,
      chain,
    },
  )

  return (
    <AcceptHighestOfferButton
      criteriaAsset={nft}
      tradeSummary={nft.tradeSummary}
      {...rest}
    />
  )
}

export const LazyAcceptHighestOfferButton = ({
  suspenseFallback,
  ...rest
}: LazyAcceptHighestOfferButtonProps) => {
  return (
    <SsrSuspense fallback={suspenseFallback ?? null}>
      <LazyAcceptHighestOfferButtonBase {...rest} />
    </SsrSuspense>
  )
}
