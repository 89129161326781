import React from "react"
import { TextBodyProps, Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { FloorPriceDifference_item$key } from "@/lib/graphql/__generated__/FloorPriceDifference_item.graphql"
import { FloorPriceDifference_perUnitPrice$key } from "@/lib/graphql/__generated__/FloorPriceDifference_perUnitPrice.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  BigNumber,
  bn,
  display,
  quantityDisplay,
} from "@/lib/helpers/numberUtils"
import { EM_DASH } from "@/lib/helpers/stringUtils"

export type RenderDifferenceProps = {
  percentageDifference: BigNumber
}

type FloorPriceDifferenceProps = {
  itemDataKey: FloorPriceDifference_item$key
  perUnitPriceDataKey: FloorPriceDifference_perUnitPrice$key
  renderDifference?: (props: RenderDifferenceProps) => TooltipProps["children"]
  overrides?: { Text: { props: Partial<TextBodyProps> } }
}

export const FloorPriceDifference = ({
  itemDataKey,
  perUnitPriceDataKey,
  renderDifference,
  overrides,
}: FloorPriceDifferenceProps) => {
  const t = useTranslate("orders")
  const item = useFragment(
    graphql`
      fragment FloorPriceDifference_item on ItemType {
        ... on AssetType {
          collection {
            statsV2 {
              floorPrice {
                eth
              }
            }
          }
        }
      }
    `,
    itemDataKey,
  )

  const perUnitPrice = useFragment(
    graphql`
      fragment FloorPriceDifference_perUnitPrice on PriceType {
        eth
      }
    `,
    perUnitPriceDataKey,
  )

  const collection = item.collection
  const rawFloorPrice = collection?.statsV2.floorPrice?.eth

  if (!rawFloorPrice) {
    return (
      <Text.Body
        size="small"
        {...overrides?.Text.props}
        className={classNames("ml-4", overrides?.Text.props.className)}
      >
        {EM_DASH}
      </Text.Body>
    )
  }

  const floorPrice = bn(rawFloorPrice)
  const ratio = bn(perUnitPrice.eth).div(floorPrice)
  const diff = ratio.minus(1)
  const percentageDiff = diff.times(100)

  return (
    <Tooltip
      content={t(
        "floorPriceDifference.tooltip",
        `Collection floor price: {{floorPrice}} ETH`,
        { floorPrice: display(floorPrice, "ETH") },
      )}
    >
      {renderDifference ? (
        renderDifference({
          percentageDifference: percentageDiff,
        })
      ) : (
        <Text.Body
          size="small"
          {...overrides?.Text.props}
          className={classNames("ml-4", overrides?.Text.props.className)}
        >
          {percentageDiff.isZero()
            ? t("floorPriceDifference.atFloor", "At floor")
            : t(
                "floorPriceDifference.notAtFloor",
                `{{quantity}}% {{percentageDiff}}`,
                {
                  quantity: quantityDisplay(percentageDiff.abs().toFixed(0)),
                  percentageDiff: percentageDiff.isNegative()
                    ? "below"
                    : "above",
                },
              )}
        </Text.Body>
      )}
    </Tooltip>
  )
}
