import React from "react"
import { Text, Alert } from "@opensea/ui-kit"
import { useTranslate } from "@/hooks/useTranslate"
import { NumberInput, basisPointsToPercentage } from "@/lib/helpers/numberUtils"
import { CreatorFeeSupportIcon } from "../CreatorFeeSupportIcon.react"

type Props = {
  basisPoints: NumberInput
}

export const CreatorFeeShowSupport = ({ basisPoints }: Props) => {
  const t = useTranslate("common")

  return (
    <Alert>
      <CreatorFeeSupportIcon />
      <Alert.Content>
        <Text.Body size="tiny" weight="semibold">
          {t(
            "creatorFeeShowSupport.title",
            "Show your support for this creator",
          )}
        </Text.Body>
        <Text.Body className="text-secondary" size="tiny">
          {t(
            "creatorFeeShowSupport.body",
            "They suggested creator earnings of {{percentage}}%.",
            {
              percentage: basisPointsToPercentage(basisPoints),
            },
          )}
        </Text.Body>
      </Alert.Content>
    </Alert>
  )
}
