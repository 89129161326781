import { getTrackingFn } from "@/lib/analytics/utils"

type Source =
  | "item-sell-page"
  | "bulk-listing-page"
  | "accept-offer-modal"
  | "semi-fungible-accept-offer-modal"
  | "edit-listing-modal"

type FeeEventParams = {
  source: Source
  isMaxFees: boolean
  optionalBasisPoints: number
  maxBasisPoints: number
  percent: number
}

export const trackOptionalCreatorFeeHidden = getTrackingFn<{ source: Source }>(
  "optional creator fee hidden",
)

export const trackOptionalCreatorFeeShown = getTrackingFn<FeeEventParams>(
  "optional creator fee shown",
)

export const trackOptionalCreatorFeeSubmitted = getTrackingFn<FeeEventParams>(
  "optional creator fee submitted",
)
