import React, { useCallback } from "react"
import { Flex, Icon, UnstyledButton, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Button, ButtonProps } from "@/design-system/Button"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Tooltip } from "@/design-system/Tooltip"
import { AcceptOfferModalContent } from "@/features/orders/components/AcceptOfferModalContent"
import {
  PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD,
  PriceWarningModal,
} from "@/features/sell/components/SellFlowMultiStepModal/PriceWarningModal.react"
import { useIsOrderFulfillAuthEnabled } from "@/hooks/useFlag"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useTranslate } from "@/hooks/useTranslate"
import {
  AcceptOfferButton_asset$data,
  AcceptOfferButton_asset$key,
} from "@/lib/graphql/__generated__/AcceptOfferButton_asset.graphql"
import { AcceptOfferButton_order$key } from "@/lib/graphql/__generated__/AcceptOfferButton_order.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import {
  bn,
  normalizePriceDisplay,
  percentageDifference,
} from "@/lib/helpers/numberUtils"
import { Source, trackClickAcceptOffer } from "../analytics"

export type AcceptOfferButtonProps = {
  isIcon?: boolean
  order: AcceptOfferButton_order$key
  source: Source
  criteriaAsset?: AcceptOfferButton_asset$key
  onClick?: () => unknown
  onComplete?: () => unknown
  trigger?: (open: () => void) => React.ReactNode
} & Omit<ButtonProps, "href" | "onClick" | "order">

export const AcceptOfferButton = ({
  isIcon,
  order: orderDataKey,
  criteriaAsset: criteriaAssetDataKey,
  onClick,
  onComplete,
  children,
  source,
  trigger,
  ...buttonProps
}: AcceptOfferButtonProps) => {
  const isOrderFulfillAuthEnabled = useIsOrderFulfillAuthEnabled()
  const viewer = useActiveAccount()
  const t = useTranslate("orders")

  const order = useFragment(
    graphql`
      fragment AcceptOfferButton_order on OrderV2Type
      @argumentDefinitions(
        identity: { type: "IdentityInputType!", defaultValue: {} }
        skipOwnedQuantity: { type: "Boolean", defaultValue: false }
      ) {
        relayId
        side
        orderType
        item {
          __typename

          ... on AssetType {
            acceptOfferDisabled {
              __typename
            }
            collection {
              statsV2 {
                floorPrice {
                  eth
                }
              }
            }
            chain {
              identifier
            }
            ownedQuantity(identity: $identity) @skip(if: $skipOwnedQuantity)
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...itemEvents_dataV2
          }

          ... on AssetBundleType {
            bundleCollection: collection {
              statsV2 {
                floorPrice {
                  eth
                }
              }
            }
            chain {
              identifier
            }
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    ownedQuantity(identity: $identity)
                  }
                }
              }
            }
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...itemEvents_dataV2
          }
        }
        maker {
          address
        }
        perUnitPriceType {
          eth
        }
      }
    `,
    orderDataKey,
  )

  const criteriaAsset = useFragment(
    graphql`
      fragment AcceptOfferButton_asset on AssetType
      @argumentDefinitions(
        identity: { type: "IdentityInputType!", defaultValue: {} }
      ) {
        relayId
        acceptOfferDisabled {
          __typename
        }
        ownedQuantity(identity: $identity)

        ...AcceptOfferModalContent_criteriaAsset @arguments(identity: $identity)
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...itemEvents_dataV2
      }
    `,
    criteriaAssetDataKey ?? null,
  )

  const { item, orderType, side } = order

  const { openModal: openGlobalModal } = useGlobalModal()

  const openModal = useCallback(() => {
    const collectionFloorPriceInEth =
      item.__typename === "AssetType"
        ? item.collection.statsV2.floorPrice?.eth
        : item.__typename === "AssetBundleType"
        ? item.bundleCollection?.statsV2.floorPrice?.eth
        : undefined

    const showWarningModal =
      !!collectionFloorPriceInEth &&
      percentageDifference(
        order.perUnitPriceType.eth,
        collectionFloorPriceInEth,
      ).isGreaterThan(PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD)

    const percentageBelowFloorPrice = collectionFloorPriceInEth
      ? percentageDifference(
          collectionFloorPriceInEth,
          order.perUnitPriceType.eth,
        ).abs()
      : undefined

    openGlobalModal(
      close =>
        showWarningModal && percentageBelowFloorPrice ? (
          <AcceptOfferWarningModal
            percentageBelowFloorPrice={percentageBelowFloorPrice.toString()}
            renderAcceptOfferModal={onClose => (
              <AcceptOfferModal
                criteriaAsset={criteriaAsset}
                orderRelayId={order.relayId}
                onClose={onClose}
                onComplete={onComplete}
              />
            )}
            onClose={close}
          />
        ) : (
          <AcceptOfferModal
            criteriaAsset={criteriaAsset}
            orderRelayId={order.relayId}
            onClose={close}
            onComplete={onComplete}
          />
        ),
      {
        size: "large",
      },
    )
  }, [criteriaAsset, item, openGlobalModal, order, onComplete])

  const handleClick = useCallback(
    (open: () => unknown) => () => {
      if (item.__typename !== "%other") {
        onClick?.()
        open()
        trackClickAcceptOffer(criteriaAsset ?? item, { source })
      }
    },
    [criteriaAsset, item, onClick, source],
  )

  if (!viewer || item.__typename === "%other" || side !== "BID") {
    return null
  }

  const isOwner =
    item.__typename === "AssetType"
      ? bn(criteriaAsset?.ownedQuantity ?? item.ownedQuantity ?? "0").gt(0)
      : getNodes(item.assetQuantities).every(
          ({ asset }) => asset.ownedQuantity && bn(asset.ownedQuantity).gt(0),
        )
  const isOfferCreator = viewer.address === order.maker.address

  if (!isOwner || isOfferCreator) {
    return null
  }

  const isAcceptOfferDisabled =
    orderType === "CRITERIA"
      ? !!criteriaAsset?.acceptOfferDisabled
      : item.__typename === "AssetType" && !!item.acceptOfferDisabled

  return (
    <NetworkUnsupportedGate
      chainIdentifier={item.chain.identifier}
      shouldAuthenticate={isOrderFulfillAuthEnabled}
    >
      {({ handleIfNotSupported }) =>
        trigger ? (
          trigger(handleIfNotSupported(handleClick(openModal)))
        ) : isIcon ? (
          <Tooltip content={t("bestOffer.accept", "Accept best offer")}>
            <Flex className={classNames("items-center", buttonProps.className)}>
              <UnstyledButton
                disabled={isAcceptOfferDisabled}
                onClick={e => {
                  e.stopPropagation()
                  return handleIfNotSupported(handleClick(openModal))()
                }}
              >
                <Icon value="price_check" />
              </UnstyledButton>
            </Flex>
          </Tooltip>
        ) : (
          <Button
            disabled={isAcceptOfferDisabled}
            variant="secondary"
            onClick={handleIfNotSupported(handleClick(openModal))}
            {...buttonProps}
          >
            {children ?? t("accept", "Accept")}
          </Button>
        )
      }
    </NetworkUnsupportedGate>
  )
}

const AcceptOfferModal = ({
  criteriaAsset,
  orderRelayId,
  onClose,
  onComplete,
}: {
  criteriaAsset: AcceptOfferButton_asset$data | null
  orderRelayId: string
  onClose: () => unknown
  onComplete?: () => unknown
}) => (
  <AcceptOfferModalContent
    criteriaAsset={criteriaAsset ?? undefined}
    orderId={orderRelayId}
    onClose={onClose}
    onComplete={onComplete}
  />
)

const AcceptOfferWarningModal = ({
  percentageBelowFloorPrice,
  renderAcceptOfferModal,
  onClose,
}: {
  percentageBelowFloorPrice: string
  renderAcceptOfferModal: (onClose: () => unknown) => React.ReactNode
  onClose: () => unknown
}) => {
  const { onNext } = useMultiStepFlowContext()
  const t = useTranslate("orders")

  return (
    <PriceWarningModal
      priceWarningActionMessage={t("acceptOffer", "Accept offer")}
      priceWarningHeader={t("acceptLowOffer", "Accept low offer?")}
      priceWarningMessage={t(
        "priceWarningModal.priceWarning.message",
        "This offer is {{percent}} below the floor price for this collection.",
        {
          percent: (
            <b>{`${normalizePriceDisplay(
              percentageBelowFloorPrice.toString(),
              0,
            )}%`}</b>
          ),
        },
      )}
      onClose={onClose}
      onConfirm={() => onNext(renderAcceptOfferModal(onClose))}
    />
  )
}
