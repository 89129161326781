import React, { useMemo } from "react"
import { FlexColumn } from "@opensea/ui-kit"
import { format } from "date-fns"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { useRouter } from "@/hooks/useRouter"
import {
  dateFromISO8601,
  getTimeZoneAbbreviation,
} from "@/lib/helpers/datetime"

type Props = {
  date: Date
  children: React.ReactNode
}

export const OffersTableDateTooltip = ({ date, children }: Props) => {
  const { locale } = useRouter()

  const parsedDate = dateFromISO8601(date.toISOString())

  const renderExactExpirationDate = useMemo(() => {
    return (
      <FlexColumn>
        <Block>{format(parsedDate, "MMMM d, yyyy")}</Block>
        <Block marginTop="4px">
          {getTimeZoneAbbreviation(parsedDate, locale, "h:mm a zzz")}
        </Block>
      </FlexColumn>
    )
  }, [parsedDate, locale])

  return (
    <Tooltip animation="fade-vertical" content={renderExactExpirationDate}>
      <Block as="span">{children}</Block>
    </Tooltip>
  )
}
