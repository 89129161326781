import React, { useState } from "react"
import { Flex, Input } from "@opensea/ui-kit"
import { FieldError } from "react-hook-form"
import styled, { css } from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { PaymentAssetOption } from "@/components/trade/PaymentTokenInput/types"
import { Block } from "@/design-system/Block"
import { FormControl, FormControlProps } from "@/design-system/FormControl"
import { Select, SelectProps } from "@/design-system/Select"
import { useTranslate } from "@/hooks/useTranslate"
import { bn, displayFiat } from "@/lib/helpers/numberUtils"

export type PaymentTokenInputProps = {
  autoFocus?: boolean
  paymentAssetOptions: PaymentAssetOption[]
  error?: FieldError
  warning?: React.ReactNode
  paymentAssetRelayId?: string
  label?: string
  placeholder?: string
  price?: string
  quantity?: number
  name?: string
  id?: string
  disabled?: boolean
  onChange: (price: string) => unknown
  onChangePaymentAsset?: (relayId: string) => unknown
  captionLeft?: FormControlProps["captionLeft"]
  captionRight?: FormControlProps["captionRight"]
  hideLabel?: boolean
  priceTabIndex?: number
  paymentAssetTabIndex?: number
  onBlur?: () => void
  overrides?: {
    Select?: Partial<SelectProps> & {
      $width?: React.CSSProperties["width"]
    }
  }
}

export const PaymentTokenInput = ({
  autoFocus,
  paymentAssetOptions,
  disabled,
  error,
  warning,
  paymentAssetRelayId,
  label,
  placeholder,
  price,
  quantity,
  onChange,
  onChangePaymentAsset,
  name,
  id,
  captionRight,
  captionLeft,
  hideLabel = false,
  priceTabIndex,
  paymentAssetTabIndex,
  onBlur,
  overrides,
}: PaymentTokenInputProps) => {
  const t = useTranslate("components")

  if (!placeholder) {
    placeholder = t("paymentTokenInput.placeholder", "Amount")
  }

  const paymentAsset = paymentAssetOptions.find(
    paymentAssetOption => paymentAssetOption.value === paymentAssetRelayId,
  )

  const usdSpotPrice = paymentAsset?.usdSpotPrice
  const usdPrice =
    usdSpotPrice && price && !bn(price).isNaN()
      ? bn(price).multipliedBy(usdSpotPrice)
      : undefined
  const totalPrice = quantity ? usdPrice?.times(quantity) : undefined
  const defaultCaption =
    usdPrice && totalPrice
      ? quantity
        ? quantity > 1
          ? t(
              "paymentTokenInput.totalMany.each",
              "{{total}} Total ({{each}} each)",
              {
                total: displayFiat(totalPrice),
                each: displayFiat(usdPrice),
              },
            )
          : t("paymentTokenInput.totalMany.default", "{{total}} Total", {
              total: displayFiat(totalPrice),
            })
        : undefined
      : usdPrice
      ? displayFiat(usdPrice)
      : undefined

  const singlePaymentAsset = paymentAssetOptions.length === 1
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Block alignItems="center" maxWidth="100%">
      <FormControl
        captionLeft={
          <Overflow lines={1}>{captionLeft ?? defaultCaption}</Overflow>
        }
        captionRight={
          !error && !warning && <Overflow lines={1}>{captionRight}</Overflow>
        }
        error={error?.message}
        hideLabel={hideLabel}
        htmlFor={name}
        label={label}
        warning={warning}
      >
        <Flex>
          <StyledInput
            $isFocused={isFocused}
            autoFocus={autoFocus}
            disabled={disabled}
            error={!!error}
            id={id}
            name={name}
            placeholder={placeholder}
            tabIndex={priceTabIndex}
            value={price}
            onBlur={() => {
              onBlur?.()
              setIsFocused(false)
            }}
            onChange={e => onChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
          />
          <StyledSelect
            $error={!!error}
            $isFocused={isFocused}
            $singlePaymentAsset={singlePaymentAsset}
            clearable={false}
            disabled={paymentAssetOptions.length <= 1 || disabled}
            endEnhancer={singlePaymentAsset ? <></> : undefined}
            excludeSelectedOption
            options={paymentAssetOptions}
            overrides={{
              Input: {
                props: {
                  onFocus: () => setIsFocused(true),
                  onBlur: () => setIsFocused(false),
                },
              },
            }}
            readOnly
            tabIndex={paymentAssetTabIndex}
            value={paymentAssetRelayId}
            onSelect={option =>
              option?.value && onChangePaymentAsset?.(option.value)
            }
            {...overrides?.Select}
          />
        </Flex>
      </FormControl>
    </Block>
  )
}

const StyledInput = styled(Input)<{
  $isFocused: boolean
}>`
  background: transparent;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-color: ${props =>
    props.$isFocused ? props.theme.colors.gray : undefined};
  transition:
    border-color ease-in-out 0.25s,
    background-color ease-in-out 0.25s;
`

const StyledSelect = styled(Select)<{
  $singlePaymentAsset: boolean
  $isFocused: boolean
  $error: boolean
  $width?: React.CSSProperties["width"]
}>`
  background: transparent;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left-width: "1px";
  flex: none;
  font-weight: 600;
  margin-left: -1px;
  width: ${props => props.$width ?? "116px"};
  border-color: ${props =>
    props.$error
      ? props.theme.colors.error
      : props.$isFocused
      ? props.theme.colors.gray
      : "undefined"};
  transition:
    border-color ease-in-out 0.25s,
    background-color ease-in-out 0.25s;

  ${props =>
    props.$singlePaymentAsset &&
    css`
      opacity: 1;
    `}
`
