/**
 * @generated SignedSource<<81235a11c2516f8021cc9a33f253e3af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AcceptOfferButton_asset$data = {
  readonly acceptOfferDisabled: {
    readonly __typename: "AcceptOfferDisabledType";
  } | null;
  readonly ownedQuantity: string | null;
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferModalContent_criteriaAsset" | "itemEvents_dataV2">;
  readonly " $fragmentType": "AcceptOfferButton_asset";
};
export type AcceptOfferButton_asset$key = {
  readonly " $data"?: AcceptOfferButton_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptOfferButton_asset">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "identity",
    "variableName": "identity"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AcceptOfferButton_asset",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AcceptOfferDisabledType",
      "kind": "LinkedField",
      "name": "acceptOfferDisabled",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "ownedQuantity",
      "storageKey": null
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "AcceptOfferModalContent_criteriaAsset"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "itemEvents_dataV2",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "type": "ItemType",
          "abstractKey": "__isItemType"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};
})();

(node as any).hash = "257ab1e13ee1596e630ed1113088d8de";

export default node;
