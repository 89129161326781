/**
 * @generated SignedSource<<90cf30698f85da1f086639a2705bf92c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemOfferDetails_item$data = {
  readonly __typename: "AssetBundleType";
  readonly assetQuantities: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
        };
      } | null;
    } | null>;
  };
  readonly bundleCollection: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
  } | null;
  readonly displayName: string;
  readonly " $fragmentType": "ItemOfferDetails_item";
} | {
  readonly __typename: "AssetType";
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
  };
  readonly displayName: string;
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  readonly " $fragmentType": "ItemOfferDetails_item";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ItemOfferDetails_item";
};
export type ItemOfferDetails_item$key = {
  readonly " $data"?: ItemOfferDetails_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemOfferDetails_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CollectionLink_collection"
  }
],
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "StackedAssetMedia_assets"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemOfferDetails_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": "bundleCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 18
            }
          ],
          "concreteType": "AssetQuantityTypeConnection",
          "kind": "LinkedField",
          "name": "assetQuantities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetQuantityTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetType",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "assetQuantities(first:18)"
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "c8329577a5c2daed141da6460687e7b3";

export default node;
