import { graphql } from "react-relay"
import { readOrderFees_order$data } from "@/lib/graphql/__generated__/readOrderFees_order.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

type OrderFees = {
  enforcedBasisPoints: number
  openseaSellerFeeBasisPoints: number
  getCanApplyOptionalFees: (maxBasisPoints: number) => boolean
  getCorrectedOptionalFeeBasisPointsForAcceptingOrder: ({
    suppliedBasisPoints,
    maxBasisPoints,
  }: {
    suppliedBasisPoints: number
    maxBasisPoints: number
  }) => number | undefined
}

export const readOrderFees = inlineFragmentize<
  readOrderFees_order$data,
  OrderFees
>(
  graphql`
    fragment readOrderFees_order on OrderV2Type @inline {
      makerFees(first: 10) {
        edges {
          node {
            basisPoints
            isOpenseaFee
          }
        }
      }
      takerFees(first: 10) {
        edges {
          node {
            basisPoints
            isOpenseaFee
          }
        }
      }
    }
  `,
  order => {
    const makerFees = getNodes(order.makerFees)
    const takerFees = getNodes(order.takerFees)
    const fees = makerFees.length > 0 ? makerFees : takerFees

    const enforcedBasisPoints = fees
      .filter(fee => !fee.isOpenseaFee)
      .reduce((acc, fee) => acc + fee.basisPoints, 0)

    const openseaSellerFeeBasisPoints = fees
      .filter(fee => fee.isOpenseaFee)
      .reduce((acc, fee) => acc + fee.basisPoints, 0)

    const getCanApplyOptionalFees = (maxBasisPoints: number) =>
      maxBasisPoints - enforcedBasisPoints > 0

    return {
      enforcedBasisPoints,
      openseaSellerFeeBasisPoints,
      // This happens if the offer was created before optional creator fees was
      // enabled. By checking if there enforced fees are less than the max, we can
      // determine if the optional creator fee should be shown.
      getCanApplyOptionalFees,

      // We should never over-supply basis points. If the enforced fees are present
      // we need to always subtract them from the supplied basis points.
      getCorrectedOptionalFeeBasisPointsForAcceptingOrder: ({
        suppliedBasisPoints,
        maxBasisPoints,
      }: {
        suppliedBasisPoints: number
        maxBasisPoints: number
      }) =>
        getCanApplyOptionalFees(maxBasisPoints) &&
        !Number.isNaN(suppliedBasisPoints)
          ? Math.max(0, suppliedBasisPoints - enforcedBasisPoints)
          : undefined,
    }
  },
)
