/**
 * @generated SignedSource<<ecb3721da55996b225cbe36575ac6b2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceFeeText_orders$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"readOrderFees_order">;
  readonly " $fragmentType": "ServiceFeeText_orders";
}>;
export type ServiceFeeText_orders$key = ReadonlyArray<{
  readonly " $data"?: ServiceFeeText_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceFeeText_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeeTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeeType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basisPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpenseaFee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ServiceFeeText_orders",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOrderFees_order",
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "makerFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "makerFees(first:10)"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "takerFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "takerFees(first:10)"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "39aa79c4f3d4b9266b3f3ee82e0bc423";

export default node;
