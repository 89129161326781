import { graphql } from "react-relay"
import { _FragmentRefs } from "relay-runtime"
import { utils_PaymentAssetOption$data } from "@/lib/graphql/__generated__/utils_PaymentAssetOption.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { PaymentAssetOption } from "./types"

export const readPaymentAssetOption =
  inlineFragmentize<utils_PaymentAssetOption$data>(
    graphql`
      fragment utils_PaymentAssetOption on PaymentAssetType @inline {
        relayId
        symbol
        displayImageUrl
        usdSpotPrice
        decimals
      }
    `,
    p => p,
  )

export const mapPaymentAssetOption = (
  paymentAsset:
    | utils_PaymentAssetOption$data
    | _FragmentRefs<"utils_PaymentAssetOption">,
) => {
  const { relayId, symbol, displayImageUrl, usdSpotPrice, decimals } =
    readPaymentAssetOption(paymentAsset)
  return {
    label: symbol,
    value: relayId,
    avatar: {
      src: displayImageUrl,
    },
    usdSpotPrice,
    displayImageUrl,
    decimals,
  }
}

export const getPaymentAssetOptions = (
  paymentAssets: ReadonlyArray<
    utils_PaymentAssetOption$data | _FragmentRefs<"utils_PaymentAssetOption">
  >,
): PaymentAssetOption[] => {
  return paymentAssets.map(mapPaymentAssetOption)
}
