/**
 * @generated SignedSource<<8d80b00c7d34c213ffa19a28239569e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type utils_PaymentAssetOption$data = {
  readonly decimals: number;
  readonly displayImageUrl: string;
  readonly relayId: string;
  readonly symbol: string;
  readonly usdSpotPrice: number;
  readonly " $fragmentType": "utils_PaymentAssetOption";
};
export type utils_PaymentAssetOption$key = {
  readonly " $data"?: utils_PaymentAssetOption$data;
  readonly " $fragmentSpreads": FragmentRefs<"utils_PaymentAssetOption">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "utils_PaymentAssetOption"
};

(node as any).hash = "44ca6b1c1163b38ee3bf87cd3626db09";

export default node;
