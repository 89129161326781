import React from "react"

export const CreatorFeeSupportIcon = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        height="24"
        id="mask0_1200_56060"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
        width="24"
        x="0"
        y="0"
      >
        <rect fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_1200_56060)">
        <path
          d="M16 13C15.2333 12.3 14.4875 11.6125 13.7625 10.9375C13.0375 10.2625 12.3958 9.6 11.8375 8.95C11.2792 8.3 10.8333 7.67083 10.5 7.0625C10.1667 6.45417 10 5.86667 10 5.3C10 4.36667 10.3167 3.58333 10.95 2.95C11.5833 2.31667 12.3667 2 13.3 2C13.8333 2 14.3333 2.1125 14.8 2.3375C15.2667 2.5625 15.6667 2.86667 16 3.25C16.3333 2.86667 16.7333 2.5625 17.2 2.3375C17.6667 2.1125 18.1667 2 18.7 2C19.6333 2 20.4167 2.31667 21.05 2.95C21.6833 3.58333 22 4.36667 22 5.3C22 5.86667 21.8333 6.45417 21.5 7.0625C21.1667 7.67083 20.7208 8.3 20.1625 8.95C19.6042 9.6 18.9667 10.2625 18.25 10.9375C17.5333 11.6125 16.7833 12.3 16 13ZM14 22.5L7 20.55V11H8.95L15.15 13.3C15.7 13.5 16.1458 13.85 16.4875 14.35C16.8292 14.85 17 15.4 17 16H15C14.3 16 13.75 15.975 13.35 15.925C12.95 15.875 12.6 15.8 12.3 15.7L10.3 15.05L10 16L11.575 16.575C12.0417 16.7583 12.4667 16.875 12.85 16.925C13.2333 16.975 13.6833 17 14.2 17H19C20.1 17 20.875 17.1792 21.325 17.5375C21.775 17.8958 22 18.3833 22 19V20L14 22.5ZM1 22V11H5V22H1Z"
          fill="url(#paint0_linear_1200_56060)"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1200_56060"
          x1="27"
          x2="1"
          y1="-2"
          y2="24"
        >
          <stop stopColor="#E62929" />
          <stop offset="1" stopColor="#EB6B6B" />
        </linearGradient>
      </defs>
    </svg>
  )
}
