import { graphql } from "relay-runtime"
import { readOptionalCreatorFees_item$data } from "@/lib/graphql/__generated__/readOptionalCreatorFees_item.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

export const readOptionalCreatorFees = inlineFragmentize<
  readOptionalCreatorFees_item$data,
  {
    getShowOptionalCreatorFee: (canApplyOptionalFees: boolean) => boolean
    maxBasisPoints: number
  }
>(
  graphql`
    fragment readOptionalCreatorFees_item on ItemType @inline {
      __typename
      ... on AssetType {
        collection {
          isCreatorFeesEnforced
          totalCreatorFeeBasisPoints
        }
      }
    }
  `,
  item => {
    return {
      getShowOptionalCreatorFee: (canApplyOptionalFees: boolean) =>
        canApplyOptionalFees &&
        item.__typename === "AssetType" &&
        !item.collection.isCreatorFeesEnforced,
      maxBasisPoints:
        item.__typename === "AssetType"
          ? item.collection.totalCreatorFeeBasisPoints
          : 0,
    }
  },
)
