import React from "react"
import { TextBodyProps, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { OffersTableDateTooltip } from "@/features/account/components/OffersTable/tooltips/OffersTableDateTooltip.react"
import { ExpirationDate_data$key } from "@/lib/graphql/__generated__/ExpirationDate_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { useFormattedExpirationDate } from "./utils"

type ExpirationDateProps = {
  dataKey: ExpirationDate_data$key
  maxExpirationDate?: Date
  alwaysRelative?: boolean
} & Partial<TextBodyProps>

export const ExpirationDate = ({
  dataKey,
  maxExpirationDate,
  alwaysRelative,
  ...textProps
}: ExpirationDateProps) => {
  const data = useFragment<ExpirationDate_data$key>(
    graphql`
      fragment ExpirationDate_data on OrderV2Type {
        closedAt
      }
    `,
    dataKey,
  )

  const { closedAt } = data
  const closedAtDate = closedAt ? dateFromISO8601(closedAt) : null

  const { formattedExpirationDate } = useFormattedExpirationDate(closedAtDate, {
    maxExpirationDate,
    alwaysRelative,
  })

  return (
    <OffersTableDateTooltip date={closedAtDate || new Date()}>
      <Text size="small" {...textProps}>
        {formattedExpirationDate}
      </Text>
    </OffersTableDateTooltip>
  )
}
