import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList/"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { AssetSuccessModalContent } from "@/components/modals/AssetSuccessModalContent.react"
import { InvalidOrderModalContent } from "@/components/trade/InvalidOrderModalContent.react"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useShoppingCartAddOrRemoveOrders } from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useItemAnalyticsContext } from "@/lib/analytics/ItemAnalyticsContext"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  FulfillActionModalQuery,
  FulfillActionModalQuery$variables,
} from "@/lib/graphql/__generated__/FulfillActionModalQuery.graphql"
import { getFirstGraphqlResponseErrorStatus } from "@/lib/graphql/error"
import { captureNoncriticalError } from "@/lib/sentry"

type Props = {
  assetIDs: string[]
  itemName?: string
  onClose: () => unknown
  onComplete?: () => unknown
} & FulfillActionModalQuery$variables

const LazyFulfillActionModalBase = ({
  assetIDs,
  giftRecipientAddress,
  itemName,
  onClose,
  onComplete,
  orderId,
  itemFillAmount,
  takerAssetsForCriteria,
  optionalCreatorFeeBasisPoints,
}: Props) => {
  const { order } = useLazyLoadQuery<FulfillActionModalQuery>(
    graphql`
      query FulfillActionModalQuery(
        $orderId: OrderRelayID!
        $itemFillAmount: BigNumberScalar!
        $takerAssetsForCriteria: ArchetypeInputType
        $giftRecipientAddress: AddressScalar
        $optionalCreatorFeeBasisPoints: Int
      ) {
        order(order: $orderId) {
          relayId
          side
          fulfill(
            itemFillAmount: $itemFillAmount
            takerAssetsForCriteria: $takerAssetsForCriteria
            giftRecipientAddress: $giftRecipientAddress
            optionalCreatorFeeBasisPoints: $optionalCreatorFeeBasisPoints
          ) {
            actions {
              ... on FulfillOrderActionType {
                giftRecipientAddress
              }
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    {
      orderId,
      itemFillAmount,
      takerAssetsForCriteria,
      giftRecipientAddress,
      optionalCreatorFeeBasisPoints,
    },
    { fetchPolicy: "network-only" },
  )

  const { tracker } = useItemAnalyticsContext()
  const { onReplace } = useMultiStepFlowContext()
  const { removeOrder } = useShoppingCartAddOrRemoveOrders()

  useMountEffect(() => {
    tracker.trackOpenFulfillActionModal()
  })

  const finalGiftRecipientAddress = order.fulfill.actions.find(
    a => a.giftRecipientAddress,
  )?.giftRecipientAddress

  return (
    <BlockchainActionList
      dataKey={order.fulfill.actions}
      onEnd={({ createdOrder, transaction } = {}) => {
        if (createdOrder?.relayId || transaction) {
          onReplace(
            <AssetSuccessModalContent
              giftRecipientAddress={finalGiftRecipientAddress ?? undefined}
              itemName={itemName}
              mode={order.side === "ASK" ? "bought" : "sold"}
              orderId={createdOrder?.relayId}
              transaction={transaction}
              variables={{ assetIDs }}
              onClose={onClose}
              onTransactionConfirmed={() => {
                if (finalGiftRecipientAddress) {
                  tracker.trackCheckoutWithGiftRecipientSuccess()
                }
              }}
            />,
          )
        }
        // If we had this item in the cart, remove it
        removeOrder(order.relayId)
        onComplete?.()
      }}
    />
  )
}

export const FulfillActionModal = (props: Props) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={({ error }) => {
        const status = getFirstGraphqlResponseErrorStatus(error)

        if (status) {
          trackServerError({ errorCode: status })
        }

        captureNoncriticalError(error, { tags: errorTags })
        return (
          <InvalidOrderModalContent
            action="Fulfill action"
            onClose={props.onClose}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <LazyFulfillActionModalBase {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}
