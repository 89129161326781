import React, { useState } from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackOpenPriceWarningModal } from "@/lib/analytics/events/itemEvents"

export const PERCENTAGE_BELOW_FLOOR_WARNING_THRESHOLD = 80
export const PERCENTAGE_ABOVE_FLOOR_WARNING_THRESHOLD = 25

type Props = {
  onClose?: () => unknown
  priceWarningHeader: string
  priceWarningMessage: React.ReactNode | undefined
  priceWarningActionMessage: string
  onConfirm: () => unknown
}

export const PriceWarningModal = ({
  onConfirm,
  onClose,
  priceWarningHeader,
  priceWarningMessage,
  priceWarningActionMessage,
}: Props) => {
  const t = useTranslate("sell")
  const { onPrevious } = useMultiStepFlowContext()
  const [isConfirming, setIsConfirming] = useState(false)
  useMountEffect(() => trackOpenPriceWarningModal())

  const handleConfirm = async () => {
    setIsConfirming(true)
    await onConfirm()
    setIsConfirming(false)
  }

  return (
    <>
      <Modal.Header onPrevious={onPrevious}>
        <Modal.Header.Title style={{ textAlign: "center" }}>
          {priceWarningHeader}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <CenterAligned>
          <Text>{priceWarningMessage}</Text>
        </CenterAligned>
      </Modal.Body>
      <Modal.Footer>
        {onClose && (
          <Modal.Footer.Button variant="secondary" onClick={onClose}>
            {t("priceWarningModal.cancelCTA", "Cancel")}
          </Modal.Footer.Button>
        )}
        <Modal.Footer.Button
          disabled={isConfirming}
          isLoading={isConfirming}
          onClick={handleConfirm}
        >
          {priceWarningActionMessage}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
