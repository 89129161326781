import { Flex } from "@opensea/ui-kit"
import styled, { css } from "styled-components"

// TODO (robert): Rename and move this into design system when this actually gets fleshed out by the design team
// Was initially used for Payment method selection in fiat mints but was ported over to the creator fee toggle
// https://www.figma.com/file/1lkJEJCckt6PROzmhpux9q/Optional-Creator-Fees?node-id=1156%3A53136&mode=dev
export const SecondaryToggleContainer = styled(Flex)<{
  selected?: boolean
  disabled?: boolean
  error?: boolean
}>`
  border: 1px solid;
  border-color: transparent;
  transition: background-color 0.1s linear;
  background-color: transparent;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  padding: 12px;

  ${({ theme, selected, disabled }) => css`
    border-color: ${selected
      ? theme.colors.text.primary
      : theme.colors.components.border.level2};

    ${!disabled
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${({ theme }) =>
              theme.colors.components.background.gray1};
          }
        `
      : css`
          opacity: 0.4;
          pointer-events: none;
        `};
  `}

  ${props => props.error && `border-color: ${props.theme.colors.coral}`};
`
