import React, { useEffect, useRef, useState } from "react"
import { SpaceBetween, Text, Input, FlexColumn } from "@opensea/ui-kit"
import { Control, Controller, FieldValues, Path } from "react-hook-form"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { SecondaryToggleContainer } from "@/components/common/SecondaryToggleContainer.react"
import { Block } from "@/design-system/Block"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useTranslate } from "@/hooks/useTranslate"
import {
  NumberInput,
  basisPointsToPercentage,
  bn,
  display,
  displayUSD,
  multiplyBasisPoints,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"

type ToggleState = "default" | "custom"

type Price = {
  unit: string
  usd: string
  symbol: string
}

type Props<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  // This is generally the collection's basis points, but could diverge if we're dealing with an order that was created
  // before the collection's basis points were updated.
  maxBasisPoints: NumberInput
  enforcedBasisPoints?: NumberInput
  price?: Price
  error?: boolean
}

export const CreatorFeeToggleGroup = <T extends FieldValues>({
  name,
  control,
  maxBasisPoints,
  enforcedBasisPoints,
  price,
  error,
}: Props<T>) => {
  const [toggleState, setToggleState] = useState<ToggleState>("default")
  const t = useTranslate("common")
  const [customValue, setCustomValue] = useState<string>("")
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (toggleState === "custom") {
      inputRef.current?.focus()
    }
  }, [toggleState])

  const { registerOptions } = useOptionalCreatorFeeInput({
    maxBasisPoints,
    enforcedBasisPoints,
  })

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <GridContainer>
          <SecondaryToggleContainer
            selected={toggleState === "default"}
            onClick={() => {
              setToggleState("default")
              field.onChange(basisPointsToPercentage(maxBasisPoints))
            }}
          >
            <FlexColumn className="w-full">
              <SpaceBetween>
                <Text.Body weight="semibold">
                  {basisPointsToPercentage(maxBasisPoints)}
                </Text.Body>
                <Text.Body weight="semibold">%</Text.Body>
              </SpaceBetween>
              <UsdSubtext basisPoints={maxBasisPoints} price={price} />
            </FlexColumn>
          </SecondaryToggleContainer>
          <SecondaryToggleContainer
            error={error}
            selected={toggleState === "custom"}
            onClick={() => {
              setToggleState("custom")

              field.onChange(customValue)
            }}
          >
            <FlexColumn className="w-full self-start">
              <SpaceBetween>
                {toggleState === "custom" ? (
                  <Input
                    inputRef={inputRef}
                    name={field.name}
                    overrides={{
                      Container: {
                        style: {
                          padding: 0,
                          border: "none",
                          height: 24,
                          fontWeight: 600,
                        },
                      },
                    }}
                    placeholder={t(
                      "creatorFeeToggleGroup.placeholder",
                      "Enter amount",
                    )}
                    value={customValue}
                    onChange={e => {
                      field.onChange(e)
                      setCustomValue(e.target.value)
                    }}
                  />
                ) : (
                  <Text.Body weight="semibold">
                    {customValue
                      ? customValue
                      : t("creatorFeeToggleGroup.custom", "Custom")}
                  </Text.Body>
                )}

                <Text.Body weight="semibold">%</Text.Body>
              </SpaceBetween>
              {customValue && (
                <UsdSubtext
                  basisPoints={percentageToBasisPoints(customValue)}
                  price={price}
                />
              )}
            </FlexColumn>
          </SecondaryToggleContainer>
        </GridContainer>
      )}
      rules={registerOptions}
    />
  )
}

const UsdSubtext = ({
  price,
  basisPoints,
}: {
  price?: Price
  basisPoints?: NumberInput
}) => {
  const totalFeeAmount =
    price && basisPoints
      ? multiplyBasisPoints(price.unit, bn(basisPoints).toNumber())
      : null

  return (
    <Block height="18px">
      <Overflow>
        <Text.Body className="text-secondary" size="tiny">
          {totalFeeAmount && price && (
            <>
              {display(totalFeeAmount)} {price.symbol}{" "}
              {`($${displayUSD(totalFeeAmount.multipliedBy(price.usd))})`}
            </>
          )}
        </Text.Body>
      </Overflow>
    </Block>
  )
}

const GridContainer = styled(Block)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
`
