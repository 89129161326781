/**
 * @generated SignedSource<<7213bae7f7a35baae120f4eb1600fa5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readOrderFees_order$data = {
  readonly makerFees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly basisPoints: number;
        readonly isOpenseaFee: boolean;
      } | null;
    } | null>;
  };
  readonly takerFees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly basisPoints: number;
        readonly isOpenseaFee: boolean;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "readOrderFees_order";
};
export type readOrderFees_order$key = {
  readonly " $data"?: readOrderFees_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderFees_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readOrderFees_order"
};

(node as any).hash = "5a57fc7fb6c20fe121ab04c44ef4d59a";

export default node;
