import React, { useEffect } from "react"
import {
  Icon,
  UnstyledButton,
  Separator,
  Text,
  Label,
  SpaceBetween,
  FlexColumn,
  Input,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import BigNumber from "bignumber.js"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { ExpirationDate } from "@/components/orders/ExpirationDate"
import { FloorPriceDifference } from "@/components/orders/FloorPriceDifference.react"
import { FulfillActionModal } from "@/components/orders/FulfillActionModal.react"
import { InvalidOrderModalContent } from "@/components/trade/InvalidOrderModalContent.react"
import { ServiceFeeText } from "@/components/trade/ServiceFeeText"
import { EMPTY_PRICE_DISPLAY } from "@/constants"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { FormControl } from "@/design-system/FormControl"
import { Item } from "@/design-system/Item"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { CreatorFeeShowSupport } from "@/features/creator-fees/components/CreatorFeeShowSupport"
import { CreatorFeeToggleGroup } from "@/features/creator-fees/components/CreatorFeeToggleGroup"
import { useForm } from "@/hooks/useForm"
import { useOptionalCreatorFeeInput } from "@/hooks/useOptionalCreatorFeeInput"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackOptionalCreatorFeeHidden,
  trackOptionalCreatorFeeShown,
  trackOptionalCreatorFeeSubmitted,
} from "@/lib/analytics/events/feeEvents"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { AcceptOfferModalContent_criteriaAsset$key } from "@/lib/graphql/__generated__/AcceptOfferModalContent_criteriaAsset.graphql"
import {
  AcceptOfferModalContentQuery,
  AcceptOfferModalContentQuery$variables,
} from "@/lib/graphql/__generated__/AcceptOfferModalContentQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import {
  basisPointsToPercentage,
  bn,
  display,
  percentageToBasisPoints,
} from "@/lib/helpers/numberUtils"
import { captureNoncriticalError } from "@/lib/sentry"
import { ItemOfferDetails } from "../OfferModal/components/ItemOfferDetails"
import { readOptionalCreatorFees } from "./readOptionalCreatorFees"
import { readOrderFees } from "./readOrderFees"

type Props = AcceptOfferModalContentQuery$variables & {
  criteriaAsset?: AcceptOfferModalContent_criteriaAsset$key
  onClose: () => unknown
  onComplete?: () => unknown
}

type FormData = {
  quantity: string
  totalCreatorFeePercentage: string
}

const LazyAcceptOfferModalContent = ({
  orderId,
  criteriaAsset: criteriaAssetDataKey,
  onClose,
  onComplete,
}: Props) => {
  const t = useTranslate("orders")
  const { onNext } = useMultiStepFlowContext()

  const { order } = useLazyLoadQuery<AcceptOfferModalContentQuery>(
    graphql`
      query AcceptOfferModalContentQuery($orderId: OrderRelayID!) {
        order(order: $orderId) {
          isOpen
          maker {
            ...AccountLink_data
          }
          item {
            __typename
            relayId

            ... on AssetType {
              ownedQuantity(identity: {})
              isCurrentlyFungible
              defaultRarityData {
                rank
              }
            }
            ... on AssetBundleType {
              displayName

              assetQuantities(first: 18) {
                edges {
                  node {
                    asset {
                      relayId
                    }
                  }
                }
              }
            }

            ...ItemOfferDetails_item
            ...FloorPriceDifference_item
            ...readOptionalCreatorFees_item
          }
          relayId
          remainingQuantityType
          perUnitPriceType {
            ...FloorPriceDifference_perUnitPrice
          }
          ...ExpirationDate_data
          ...OrderPrice
          ...OrderUsdPrice
          ...readOrderFees_order
          ...ServiceFeeText_orders
        }
      }
    `,
    {
      orderId,
    },
    {
      fetchPolicy: "network-only",
    },
  )

  const criteriaAsset = useFragment(
    graphql`
      fragment AcceptOfferModalContent_criteriaAsset on AssetType
      @argumentDefinitions(
        identity: { type: "IdentityInputType!", defaultValue: {} }
      ) {
        __typename
        assetContract {
          address
        }
        chain {
          identifier
        }
        tokenId
        relayId
        ownedQuantity(identity: $identity)
        isCurrentlyFungible
        defaultRarityData {
          rank
        }
        ...ItemOfferDetails_item
        ...FloorPriceDifference_item
        ...readOptionalCreatorFees_item
      }
    `,
    criteriaAssetDataKey ?? null,
  )

  const item = criteriaAsset ?? order.item
  const { isCurrentlyFungible } = item
  const rarityRank = item.defaultRarityData?.rank

  const {
    getCanApplyOptionalFees,
    enforcedBasisPoints,
    openseaSellerFeeBasisPoints,
  } = readOrderFees(order)
  const { getShowOptionalCreatorFee, maxBasisPoints } =
    readOptionalCreatorFees(item)

  const canApplyOptionalFees = getCanApplyOptionalFees(maxBasisPoints)

  const shouldShowOptionalCreatorFee =
    getShowOptionalCreatorFee(canApplyOptionalFees)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState,
    control,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      quantity: "1",
      totalCreatorFeePercentage: basisPointsToPercentage(maxBasisPoints),
    },
  })

  const maxQuantity = BigNumber.min(
    item.__typename === "AssetType" ? item.ownedQuantity ?? 0 : 1,
    order.remainingQuantityType,
  )

  const quantity = watch("quantity") || "0"

  const totalCreatorFeePercentage =
    watch("totalCreatorFeePercentage") ||
    basisPointsToPercentage(enforcedBasisPoints)

  const sanitizedCreatorFeePercentage = Math.min(
    100,
    Math.max(0, parseFloat(totalCreatorFeePercentage) || 0),
  )

  const mapQuantityAfterFees = (q: BigNumber) => {
    return q.isNaN()
      ? bn(0)
      : q.times(
          bn(1).minus(
            bn(
              percentageToBasisPoints(sanitizedCreatorFeePercentage) +
                openseaSellerFeeBasisPoints,
              4,
            ),
          ),
        )
  }

  const assetIds = criteriaAsset
    ? [criteriaAsset.relayId]
    : order.item.assetQuantities
    ? getNodes(order.item.assetQuantities).map(a => a.asset.relayId)
    : [order.item.relayId]

  useEffect(() => {
    if (shouldShowOptionalCreatorFee) {
      trackOptionalCreatorFeeShown({
        source: "accept-offer-modal",
        isMaxFees: true,
        optionalBasisPoints: maxBasisPoints,
        maxBasisPoints,
        percent: 100,
      })
    } else {
      trackOptionalCreatorFeeHidden({ source: "accept-offer-modal" })
    }
  }, [maxBasisPoints, shouldShowOptionalCreatorFee])

  const renderHeader = () => (
    <ItemOfferDetails
      className="p-0 pt-6"
      item={item}
      quantity={Number(quantity)}
      renderPriceSide={() => (
        <Item.Side className="max-w-full">
          <Item.Description>
            <Text className="flex justify-end text-secondary" size="small">
              {isCurrentlyFungible
                ? t(
                    "acceptOfferModalContent.averageOfferValue",
                    "Average offer value",
                  )
                : t("acceptOfferModalContent.offerValue", "Offer value")}
            </Text>
          </Item.Description>
          <Item.Title>
            <Text
              className="flex justify-end"
              data-testid="AcceptOfferModalContent--total-price"
              size="medium"
              weight="semibold"
            >
              <OrderPrice
                map={price => price.times(quantity)}
                order={order}
                variant="perUnit"
              />
            </Text>
          </Item.Title>
          <Item.Description>
            <Text className="flex justify-end" size="small">
              <OrderUsdPrice
                map={price => price.times(quantity)}
                order={order}
                secondary
                variant="perUnit"
              />
            </Text>
          </Item.Description>
        </Item.Side>
      )}
      renderRarityRank={() => (
        <>
          {rarityRank && (
            <Text className="text-secondary" size="small">
              {t("rarityRank", "Rarity #{{rarityRank}}", {
                rarityRank,
              })}
            </Text>
          )}
        </>
      )}
    />
  )

  const renderQuantity = () =>
    maxQuantity.gt(1) ? (
      <>
        <FormControl
          error={formState.errors.quantity?.message}
          hideLabel
          // The actual label is below
          label={undefined}
        >
          <SpaceBetween className="items-center">
            <FlexColumn>
              <Text weight="semibold">
                <Label htmlFor="quantity">
                  {t("acceptOfferModal.quantity", "Quantity")}
                </Label>
              </Text>
              <Text className="text-secondary" size="small">
                {t("acceptOfferModal.available", "{{available}} available", {
                  available: maxQuantity.toString(),
                })}
              </Text>
            </FlexColumn>

            <Input
              {...register("quantity", {
                required: t(
                  "acceptOfferModal.form.quantityRequired.error",
                  "Quantity is required",
                ),
                min: {
                  value: 1,
                  message: t(
                    "acceptOfferModal.form.min.error",
                    "Please enter a valid quantity",
                  ),
                },
                max: {
                  value: maxQuantity.toString(),
                  message: t(
                    "acceptOfferModal.form.max.error",
                    "Please enter a max of {{maxQuantity}}",
                    { maxQuantity: maxQuantity.toString() },
                    { forceString: true },
                  ),
                },
              })}
              endEnhancer={
                <UnstyledButton
                  disabled={bn(quantity).gte(maxQuantity)}
                  onClick={() => {
                    setValue("quantity", bn(quantity).plus(1).toString(), {
                      shouldValidate: true,
                    })
                  }}
                >
                  <InteractiveIcon size={16} value="add" />
                </UnstyledButton>
              }
              overrides={{
                Input: { style: { textAlign: "center" } },
              }}
              placeholder={maxQuantity.toString()}
              startEnhancer={
                <UnstyledButton
                  disabled={bn(quantity).lte(0)}
                  onClick={() => {
                    setValue("quantity", bn(quantity).minus(1).toString(), {
                      shouldValidate: true,
                    })
                  }}
                >
                  <InteractiveIcon size={16} value="remove" />
                </UnstyledButton>
              }
              type="number"
              width="136px"
            />
          </SpaceBetween>
        </FormControl>
        <Separator className="my-3" />
      </>
    ) : null

  const renderOfferDetails = () => (
    <FlexColumn className="gap-2">
      <Text weight="semibold">
        {t("acceptOfferModal.offerDetails.title", "Offer details")}
      </Text>
      {maxQuantity.gt(1) && (
        <SpaceBetween>
          <Text>
            {t("acceptOfferModal.offerDetails.unitPrice", "Unit price")}
          </Text>
          <Text>
            <OrderPrice fontWeight={400} order={order} variant="perUnit" />
          </Text>
        </SpaceBetween>
      )}
      <SpaceBetween>
        <Text>
          {t(
            "acceptOfferModal.offerDetails.floorDifference",
            "Floor difference",
          )}
        </Text>
        <FloorPriceDifference
          itemDataKey={item}
          overrides={{ Text: { props: { size: "medium" } } }}
          perUnitPriceDataKey={order.perUnitPriceType}
        />
      </SpaceBetween>
      <SpaceBetween>
        <Text>{t("acceptOfferModal.offerDetails.from", "From")}</Text>
        <Block>
          <Text>
            <AccountLink dataKey={order.maker} variant="no-image" />
          </Text>
        </Block>
      </SpaceBetween>
      <SpaceBetween>
        <Text>
          {t("acceptOfferModal.offerDetails.expiration", "Expiration")}
        </Text>
        <ExpirationDate alwaysRelative dataKey={order} size="medium" />
      </SpaceBetween>
    </FlexColumn>
  )

  const renderFees = () => (
    <FlexColumn className="mt-5">
      <Text weight="semibold">
        {t("acceptOfferModal.summary.title", "Fees")}
      </Text>
      <Separator className="my-3" />
      <SpaceBetween>
        <Text>
          {t("acceptOfferModal.offerDetails.openseaFee", "OpenSea fee")}
        </Text>
        <ServiceFeeText orders={[order]} />
      </SpaceBetween>
      <Separator className="my-3" />

      <SpaceBetween>
        <Flex alignItems="center">
          {creatorFeeInputProps.label}
          <InfoIcon
            overrides={{
              Button: {
                style: { marginLeft: "4px" },
              },
              Icon: { size: 14 },
              Tooltip: {
                interactive: true,
              },
            }}
            tooltipContent={creatorFeeInputProps.tooltipContent}
          />
        </Flex>

        <Text>
          {sanitizedCreatorFeePercentage
            ? `${display(sanitizedCreatorFeePercentage)}%`
            : EMPTY_PRICE_DISPLAY}
        </Text>
      </SpaceBetween>
      {shouldShowOptionalCreatorFee && (
        <Block marginTop="12px">{renderOptionalCreatorFee()}</Block>
      )}
    </FlexColumn>
  )

  const creatorFeeInputProps = useOptionalCreatorFeeInput({
    maxBasisPoints,
    enforcedBasisPoints,
  })

  const totalCreatorFeePercentageError =
    getValues("totalCreatorFeePercentage") &&
    formState.errors.totalCreatorFeePercentage?.message

  const renderOptionalCreatorFee = () => (
    <>
      <FormControl
        disabled={!shouldShowOptionalCreatorFee}
        error={totalCreatorFeePercentageError}
        hideLabel
        label={creatorFeeInputProps.label}
      >
        <CreatorFeeToggleGroup
          control={control}
          enforcedBasisPoints={enforcedBasisPoints}
          error={Boolean(totalCreatorFeePercentageError)}
          maxBasisPoints={maxBasisPoints}
          name="totalCreatorFeePercentage"
        />
      </FormControl>
      <Block marginTop="12px">
        <CreatorFeeShowSupport basisPoints={maxBasisPoints} />
      </Block>
    </>
  )

  const renderTotalEarnings = () => (
    <SpaceBetween>
      <Text weight="semibold">
        {t("acceptOfferModal.totalEarnings", "Total earnings")}
      </Text>
      <FlexColumn className="items-end">
        <Text weight="semibold">
          <OrderPrice
            fontWeight={600}
            map={mapQuantityAfterFees}
            order={order}
            variant="perUnit"
          />
        </Text>
        <Text className="text-secondary" size="small">
          <OrderUsdPrice
            fontWeight={400}
            map={mapQuantityAfterFees}
            order={order}
            variant="perUnit"
          />
        </Text>
      </FlexColumn>
    </SpaceBetween>
  )

  const onSubmit = handleSubmit(
    async ({ quantity, totalCreatorFeePercentage }: FormData) => {
      const { getCorrectedOptionalFeeBasisPointsForAcceptingOrder } =
        readOrderFees(order)

      const optionalCreatorFeeBasisPoints = totalCreatorFeePercentage
        ? getCorrectedOptionalFeeBasisPointsForAcceptingOrder({
            suppliedBasisPoints: percentageToBasisPoints(
              totalCreatorFeePercentage,
            ),
            maxBasisPoints,
          })
        : undefined

      if (
        shouldShowOptionalCreatorFee &&
        optionalCreatorFeeBasisPoints !== undefined
      ) {
        trackOptionalCreatorFeeSubmitted({
          source: "accept-offer-modal",
          isMaxFees: optionalCreatorFeeBasisPoints === maxBasisPoints,
          optionalBasisPoints: optionalCreatorFeeBasisPoints,
          maxBasisPoints,
          percent: optionalCreatorFeeBasisPoints / maxBasisPoints,
        })
      }

      onNext(
        <CoreMarketplaceActionTrackingContextProvider action="OfferAccept">
          <FulfillActionModal
            assetIDs={assetIds}
            itemFillAmount={quantity}
            itemName={order.item.displayName}
            optionalCreatorFeeBasisPoints={optionalCreatorFeeBasisPoints}
            orderId={order.relayId}
            takerAssetsForCriteria={
              criteriaAsset
                ? {
                    assetContractAddress: criteriaAsset.assetContract.address,
                    tokenId: criteriaAsset.tokenId,
                    chain: criteriaAsset.chain.identifier,
                  }
                : undefined
            }
            onClose={onClose}
            onComplete={onComplete}
          />
        </CoreMarketplaceActionTrackingContextProvider>,
      )
    },
  )

  if (!order.isOpen) {
    return <InvalidOrderModalContent action="Accept Offer" onClose={onClose} />
  }

  return (
    <Modal.Form onSubmit={onSubmit}>
      <Modal.Header>
        <Modal.Header.Title>
          {t("acceptOfferModal.title", "Approve sale")}
        </Modal.Header.Title>
      </Modal.Header>
      {/* This is essentially a specificity override */}
      <Modal.Body paddingTop={{ _: "0", sm: "0" }}>
        {renderHeader()}
        <Separator className="my-6" />
        {renderQuantity()}
        {renderOfferDetails()}

        {renderFees()}
        <Separator className="my-6" />
        {renderTotalEarnings()}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button disabled={!formState.isValid} type="submit">
          {t("acceptOfferModal.accept", "Accept")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal.Form>
  )
}

export const AcceptOfferModalContent = (props: Props) => {
  return (
    <ErrorBoundary
      fallback={({ error }) => {
        captureNoncriticalError(error)
        return (
          <InvalidOrderModalContent
            action="Accept Offer"
            onClose={props.onClose}
          />
        )
      }}
    >
      <SsrSuspense fallback={<ModalLoader />}>
        <LazyAcceptOfferModalContent {...props} />
      </SsrSuspense>
    </ErrorBoundary>
  )
}

const InteractiveIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};

  ${interactiveStylesPrimary};
`
